// Options
export const RPM_COMPARISON_BOARD_OPTION_DIFFERENCES = 'differences'
export const RPM_COMPARISON_BOARD_OPTION_BEST_WORST = 'best_worst'
export const RPM_COMPARISON_BOARD_OPTION_FULL_DATA = 'full_data'
export const RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE = 'model_change'

export const RPM_COMPARISON_BOARD_COLORS = [
  {
    label: 'Orange',
    value: 'orange',
  },
  {
    label: 'Purple',
    value: 'purple',
  },
  {
    label: 'Red',
    value: 'red',
  },
  {
    label: 'Cyan',
    value: 'cyan',
  },
  {
    label: 'Blue',
    value: 'blue',
  },
  {
    label: 'Emerald',
    value: 'emerald',
  },
  {
    label: 'Green',
    value: 'green',
  },
  {
    label: 'Azul',
    value: 'azul',
  },
  {
    label: 'Yellow',
    value: 'yellow',
  },
  {
    label: 'Magenta',
    value: 'magenta',
  },
]

export const RPM_COMPARISON_BOARD_NODE_MODAL_COVER_RATIO = 1.43
export const RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MARGIN = 10
export const RPM_COMPARISON_BOARD_NODE_MODAL_COLUMN_MIN_WIDTH = 129
