import _ from 'lodash'
import {
  RPM_COMPARISON_BOARD_OPTION_DIFFERENCES,
  RPM_COMPARISON_BOARD_OPTION_BEST_WORST,
  RPM_COMPARISON_BOARD_OPTION_FULL_DATA,
  RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE,
} from '../../consts/comparisonBoard'

const initialState = {
  projectId: null,
  configurationCategoryId: null,

  options: {
    [RPM_COMPARISON_BOARD_OPTION_FULL_DATA]: false,
    [RPM_COMPARISON_BOARD_OPTION_BEST_WORST]: false,
    [RPM_COMPARISON_BOARD_OPTION_DIFFERENCES]: false,
    [RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE]: false,
  },

  modelChangeItemSelectedId: null,

  productsData: {}, // An array of products indexed by id

  productsOrder: [],

  expandedRows: [], // This is a list of node definition ids
  expandedModelChangeRows: null, // This is a list of node definition ids

  nodeModal: {
    nodeDefinitionId: null,
  },

  modelChangeData: {}, // model_change_id: [node_definitions_ids]

  modelChangeItems: {}, // An array of model change items indexed by id

  editedNodes: {}, // product_id: [node_id, node_id...]
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RPM/COMPARISON_BOARD/INIT': {
      const {
        projectId,
        configurationCategoryId,
        options,
        productsData,
        modelChangeData,
        modelChangeItems,
        editedNodes,
      } = action

      return {
        ...state,
        projectId,
        configurationCategoryId,
        options: {
          ...state.options,
          ...options,
        },
        productsData: _.keyBy(productsData, 'product_id'),
        productsOrder: _.map(_.sortBy(productsData, 'order'), _product => _product.product_id),
        modelChangeData: modelChangeData !== undefined ? modelChangeData : state.modelChangeData,
        modelChangeItems: modelChangeItems !== undefined ? _.keyBy(modelChangeItems, 'id') : state.modelChangeItems,
        editedNodes: editedNodes !== undefined ? editedNodes : state.editedNodes,
      }
    }

    case 'RPM/COMPARISON_BOARD/ROW_EXPANDED': {
      const { nodeDefinitionId } = action

      if (state.expandedRows.includes(nodeDefinitionId)) {
        return state
      }

      const newExpandedRows = [...state.expandedRows]
      newExpandedRows.push(nodeDefinitionId)

      return {
        ...state,
        expandedRows: newExpandedRows,
      }
    }

    case 'RPM/COMPARISON_BOARD/ROWS_EXPANDED': {
      const { nodeDefinitionIds } = action

      const newExpandedRows = [...state.expandedRows]
      _.each(nodeDefinitionIds, id => {
        if (!state.expandedRows.includes(id)) {
          newExpandedRows.push(id)
        }
      })

      return {
        ...state,
        expandedRows: newExpandedRows,
      }
    }

    case 'RPM/COMPARISON_BOARD/ROW_COLLAPSED': {
      const { nodeDefinitionId } = action

      return {
        ...state,
        expandedRows: _.without(state.expandedRows, nodeDefinitionId),
      }
    }

    case 'RPM/COMPARISON_BOARD/ROW_MODEL_CHANGE_EXPANDED': {
      const { nodeDefinitionId } = action

      if (state.expandedModelChangeRows?.includes(nodeDefinitionId)) {
        return state
      }

      const newExpandedRows = state.expandedModelChangeRows ? [...state.expandedModelChangeRows] : []
      newExpandedRows.push(nodeDefinitionId)

      return {
        ...state,
        expandedModelChangeRows: newExpandedRows,
      }
    }

    case 'RPM/COMPARISON_BOARD/ROW_MODEL_CHANGE_COLLAPSED': {
      const { nodeDefinitionId } = action

      return {
        ...state,
        expandedModelChangeRows: _.without(state.expandedModelChangeRows, nodeDefinitionId),
      }
    }

    case 'RPM/COMPARISON_BOARD/MODEL_CHANGE_ITEM_SELECTED_UPDATE': {
      const { id } = action

      return {
        ...state,
        modelChangeItemSelectedId: id,
      }
    }

    case 'RPM/CONFIGURATION_CATEGORY_UPDATED': {
      const { item: configurationCategory } = action
      const { comparison_board: comparisonBoard } = configurationCategory

      if (
        state.configurationCategoryId !== configurationCategory.id ||
        comparisonBoard === undefined ||
        comparisonBoard === null
      ) {
        return state
      }

      return {
        ...state,
        options: {
          ...state.options,
          ...comparisonBoard.options,
        },
        productsData: _.keyBy(comparisonBoard.products_data, 'product_id'),
        productsOrder: _.map(_.sortBy(comparisonBoard?.products_data, 'order'), _product => _product.product_id),
        modelChangeData: { ...state.modelChangeData, ...comparisonBoard.model_change_data },
        modelChangeItems: _.keyBy(comparisonBoard.model_change_items, 'id'),
        editedNodes: { ...comparisonBoard.edited_nodes },
      }
    }

    case 'RPM/NODE_MODAL_TOGGLED': {
      const { nodeDefinitionId } = action

      return {
        ...state,
        nodeModal: {
          ...state.nodeModal,
          nodeDefinitionId,
        },
      }
    }

    default:
      return state
  }
}

export default reducer
